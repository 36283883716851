import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Hero from "../../components/hero";
import { GatsbyImage } from "gatsby-plugin-image";

const Contact = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-contact.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      map: file(relativePath: { eq: "map.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 727
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
      andreas: file(relativePath: { eq: "profiles/andreas-boegemann.jpg" }) {
        ...avatarTraced
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        ...avatarTraced
      }
      marcel: file(relativePath: { eq: "profiles/marcel-scheland.jpg" }) {
        ...avatarTraced
      }
    }

    fragment avatarTraced on File {
      childImageSharp {
        gatsbyImageData(
          width: 810
          placeholder: DOMINANT_COLOR
          layout: CONSTRAINED
        )
      }
    }
  `);
  return (
    <Layout lang="en" langPath="nav.contactus.link">
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Seo
        title="Your direct line to us"
        description="Whether by phone, e-mail or in person. Here you will find our contact details."
        keywords="Contact, directions, call, telephone number, email"
      />
      <Hero
        headline="How to reach us"
        subline="Need more information? Coffee? A bite to eat? All on offer next door at the Hamburg Fish Market! We look forward to your visit."
        section="Contact"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Contact"
        />
      </Hero>
      <div
        className="float-teaser float-teaser-right bg-monday-yellow vertical-offset mb-5 reveal"
        data-offset="90%"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-4">
              <address className="float-teaser-body pl-4 py-3">
                <p className="h4">Monday Consulting GmbH</p>
                <p>
                  Breite Straße 61
                  <br />
                  22767 Hamburg
                  <br />
                  <a className="hover-underline" href="tel:+494022868420">
                    +49 40 22 86 842 0
                  </a>
                  <br />
                  <a
                    className="hover-underline"
                    href="mailto:contact@monday-consulting.com"
                  >
                    contact@monday-consulting.com
                  </a>
                </p>
              </address>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-md-3 mb-5 mb-md-6">
        <div className="row align-items-top">
          <div className="col-md-6 mb-3 mt-md-4">
            <GatsbyImage
              image={images.map.childImageSharp.gatsbyImageData}
              className="w-75"
              alt="Unsere Tools"
            />
          </div>
          <div className="col-md-6">
            <h3>By car</h3>
            <p>
              Paid parking is available directly at Fischmarkt, Große Elbstraße.
              From there, it's about a 250-meter walk via De-Voß-Straße and
              Carsten-Rehder-Straße to the office building. Please note that
              this is a flood-prone area.
            </p>
            <h3>By public transport</h3>
            <p>
              The bus stop "Fischmarkt", which is located directly at the office
              building, can be reached by bus line 2. The nearest S-Bahn stops
              Königstraße or Reeperbahn can be reached with lines S1, S2 and S3.
              From there it is about a 10-minute walk to the office building.
            </p>
            <a
              href="https://www.hvv.de/linking-service/show/d5d825f474a545a4bbd6e8644011aaef"
              target="_blank"
              className="btn mt-1"
              rel="noopener noreferrer"
            >
              Click here for the public transport route planner
            </a>
          </div>
        </div>
      </div>

      <div className="container reveal">
        <div className="row mb-3">
          <h2 className="col-12 text-center">Contacts</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 mb-3">
            <div className="bg-monday-yellow h-100 p-3 text-center">
              <div className="w-75 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.andreas.childImageSharp.gatsbyImageData}
                      alt="Andreas Bögemann"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4 mb-1">Andreas Bögemann</h3>
              <span className="text-size-5 mb-2 d-block">Human Resources</span>
              <a
                className="text-sans text-size-5"
                href="mailto:jobs@monday-consulting.com"
              >
                jobs@monday-consulting.com
              </a>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-3">
            <div className="bg-monday-yellow h-100 p-3 text-center">
              <div className="w-75 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.dirk.childImageSharp.gatsbyImageData}
                      alt="Dirk Schrödter"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4 mb-1">Dirk Schrödter</h3>
              <span className="text-size-5 mb-2 d-block">
                New Business / Products
              </span>
              <a
                className="text-sans text-size-5"
                href="mailto:business@monday-consulting.com"
              >
                business@monday-consulting.com
              </a>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-3">
            <div className="bg-monday-yellow h-100 p-3 text-center">
              <div className="w-75 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.marcel.childImageSharp.gatsbyImageData}
                      alt="Marcel Scheland"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4 mb-1">Marcel Scheland</h3>
              <span className="text-size-5 mb-2 d-block">
                Corporate Communications
              </span>
              <a
                className="text-sans text-size-5"
                href="mailto:hello@monday-consulting.com"
              >
                hello@monday-consulting.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
